import { parseNFButton } from 'components/common/NFButton/parsers';
import { INFAccordionItem } from 'components/common/NFAccordion/model';
import { IPropsFaqBanner } from './models';

const parseFAQBanner = (
  faqBannerData: FAQBanner.IUmbracoFAQBannerMainProperties
): IPropsFaqBanner => {
  return {
    accordionTitle: faqBannerData.faqBannerAccordionTitle,
    dosageTitle: faqBannerData.faqBannerDosageTitle,
    img: faqBannerData.faqBannerImage,
    imageType: faqBannerData?.faqBannerImageType || 'svg',
    imagePosition: faqBannerData?.faqBannerImagePosition || 'left',
    isImageHiddenOnMobile: faqBannerData?.faqBannerIsImageHiddenOnMobile || false,
    imageAlt: faqBannerData.faqBannerImageAlt,
    btn: faqBannerData?.faqBannerCTAButton?.length
      ? { ...parseNFButton(faqBannerData.faqBannerCTAButton?.[0].properties) }
      : undefined,
    bgBannerColor: faqBannerData.faqBannerBgColour?.[0]?.properties.colorPicker?.label
      ? faqBannerData.faqBannerBgColour[0].properties.colorPicker.label
      : undefined,
    accordionItems: faqBannerData.faqBannerAccordionItems?.length
      ? faqBannerData.faqBannerAccordionItems[0].properties.items.map(
          (item: Accordion.IUmbracoAccordionCommonItemProperties): INFAccordionItem => {
            return {
              header: item.properties.header,
              content: item.properties.content,
              isOpened: item.properties.isOpened,
            };
          }
        )
      : undefined,
    downloadBtnList:
      faqBannerData.faqBannerDownloadButtons && faqBannerData.faqBannerDownloadButtons.length
        ? faqBannerData.faqBannerDownloadButtons.map(
            (item: FAQBanner.IUmbracoFAQBannerDownloadButton): NFButton.INFBtnParsedData => {
              return {
                ...parseNFButton(item.properties),
              };
            }
          )
        : undefined,
    downloadIcon: faqBannerData.faqBannerDownloadIcon,
    downloadIconText: faqBannerData.faqBannerDownloadIconText,
    downloadIconAlt: faqBannerData.faqBannerDownloadIconAlt,
    faqBannerAnchor: faqBannerData.faqBannerAnchor,
    sectionLandmark: faqBannerData.sectionLandmark,
    sectionNameForSubNav: faqBannerData.sectionNameForSubNav,
  };
};

export default parseFAQBanner;
